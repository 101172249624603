export const buyerInfoKeys = [
  'offerType',
  'Buyer_name',
  'Buyer2_name',
  'Buyer_email',
  'Buyer2_email',
  'Buyer_phone',
  'Buyer2_phone',
  'company_name',
  'company_email',
  'company_phone',
  'additional_info',
  'preapproval_pof',
  'official_offer',
  'hideBuyer_email',
  'hideBuyer_phone',
  'hideBuyer2_email',
  'hideBuyer2_phone',
  'hideCompany_email',
  'hideCompany_phone',
  'Buyer_contact_person'
];

export const financialsKeys = [
  'offerAmount',
  'earnest_money_1',
  'earnest_money_2',
  'earnest_money_days',
  'earnest_money_beholding',
  'closing_cost_credit',
  'paymentMethod',
  'transactionCash',
  'loanFixedOrAdjustable',
  'loanType',
  'loanTypeOther',
  'interestRate',
  'loanAmount',
  'selectLoanPeriod',
  'maxDiscountPoints'
];

export const priceIncludesKeys = ['items_at_zero_cost', 'price_includes', 'items_not_included','parking_spots','parking_spots_numbers','parking_spots_location','parking_pin','storage_spots_numbers','storage_spots','storage_spots_location',];

export const deadlineKeys = [
  'deadlineDate',
  'inspectionChoice',
  'postClosingPoss',
  'saleContingency',
  'homeWarranty',
  'homeWarrantyPrice',
  'asIsClause',
  'taxProration',
  'BuyerAttorney',
  'BuyerAttorney_name',
  'BuyerAttorney_email',
  'BuyerAttorney_phone',
  'Lender',
  'Lender_name',
  'Lender_email',
  'Lender_phone'
];
