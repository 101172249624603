import { useCallback, useContext, useEffect, useState } from 'react';

import notify from 'notify';
import { getUsers } from 'api/users';
import { mapUsers } from 'views/CalendarPage/mapping';
import { tryGetFirstError } from 'utils/requests';
import { DropdownOption } from 'components';
import useAuthContext from 'hooks/useAuthContext';
import WorkspaceContext from '../WorkspaceContext';
import {
  deleteNote as deleteNoteRequest,
  addNote,
  getNotes,
} from '../api';

export default () => {
  const { listingId } = useContext(WorkspaceContext);
  const [notes, setNotes] = useState<WorkspaceNote[]>();
  const [usersOptions, setUsersOptions] = useState<DropdownOption[]>([]);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [addNoteText, setAddNoteText] = useState('');

  const { authData } = useAuthContext();

  const cancelAddNote = () => {
    setIsAddingNote(false);
    setAddNoteText('');
  };

  const removeNote = async (noteId, callback) => {
    try {
      await deleteNoteRequest(noteId);
      setNotes(notes => notes?.filter(item => item.id !== noteId));
      notify('Note deleted successfully');
      if (callback) callback();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const submitNewNote = async e => {
    if (addNoteText) e.preventDefault();
    try {
      const notes = await addNote({ listingId, text: addNoteText });
      setNotes(notes);
      cancelAddNote();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const fetchNotes = useCallback(async () => {
    try {
      if(authData?.superAdmin){
        setNotes(await getNotes(listingId));
      }else{
        setNotes([]);
      }
      
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  useEffect(() => {
    (async () => {
      try {
        const users = await getUsers();
        const mappedData = mapUsers(users);
        setUsersOptions(mappedData);
      } catch (err) {
        notify(err.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (!listingId) return;
    fetchNotes();
  }, [fetchNotes, listingId]);

  return {
    listingId,
    notes,
    isAddingNote,
    setIsAddingNote,
    usersOptions,
    addNoteText,
    setAddNoteText,
    removeNote,
    submitNewNote,
    cancelAddNote,
  };
};
