export const stepPropertyInfoDataKeys = [
  'saleOrRent',
  'listing_type_hr',
  'beds',
  'baths',
  'sqft',
  'condoPUD',
  'lotSize',
  'condo_parking_number',
  'condo_parking_info',
  'condo_storage_number',
  'condo_storage_info',
  'managementCompanyContact.name',
  'managementCompanyContact.email',
  'managementCompanyContact.phone',
  'managementCompanyContact.assessments',
  'managementCompanyContact.assessmentsSpecial'
];

export const stepPropertyDetailsDataKeys = [
  'available_on',
  'purchased_on',
  'community',
  'property_tax_id',
  'property_description',
  'dryer',
  'freezer',
  'garbage_disposal',
  'microwave',
  'range_oven',
  'dishwasher',
  'refrigerator',
  'trash_compactor',
  'washer',
  'laundry',
  'pets_none',
  'cats',
  'small_dogs',
  'large_dogs',
  'attic',
  'cable_ready',
  'fireplace',
  'intercom',
  'jetted_tube',
  'security_alarm',
  'shutters_snow_windows',
  'images',
  'skylights',
  'vaulted_ceiling',
  'walkin_closet',
  'wired',
  'balcony_patio',
  'barbecue',
  'deck',
  'dock',
  'garden',
  'hot_tub_spa',
  'lawn',
  'pond',
  'pool',
  'porch',
  'sprinkler',
  'waterview',
  'concierge',
  'gym',
  'guest_house',
  'playground',
  'recreational',
  'restaurant',
  'sauna',
  'sportcourt',
  'security',
  'doorman',
  'controlled',
  'elevator',
  'fire_sprinkler',
  'garage_door_opener',
  'valet',
  'wheelchair',
  'smoke_detectors',
  'parking_spaces',
  'parking_type',
  'parking_number',
  'parking_info',
  'storage_number',
  'storage_info',
  'year_built',
  'utility_features',
  'ac_heater',
  'flooring_type'
];

export const stepFinancialsDataKeys = [
  'current_price',
  'application_fee',
  'association_fee',
  'listing_date_termination',
  'start_listing_earlier',
  'listing_suggested_price',
  'marketing_cost',
  'package_price_total',
  'sale_commission',
  'coop_commission',
  'mls_fee',
  'additional_sale_commission',
  'reimbursement',
  'dual_agency',
  'install_lockbox',
  'property_leased',
  'lease_expiry_date',
  'price_includes',
  'price_includes_sump_pump',
  'price_includes_central_ac',
  'price_includes_fireplace',
  'price_includes_buit_in_cabinets_and_shelves',
  'price_includes_oven_range',
  'price_includes_window_ac',
  'price_includes_microwave',
  'price_includes_electronic_air_filters',
  'price_includes_fireplace_gas_log',
  'price_includes_ceiling_fan',
  'price_includes_dishwasher',
  'price_includes_intercom',
  'price_includes_buit_in_central_humidifier',
  'price_includes_firewood',
  'price_includes_radiator_covers',
  'price_includes_garbage_disposals',
  'price_includes_electronic_security_system',
  'price_includes_attached_gas_grill',
  'price_includes_vegetation',
  'price_includes_trash_compactor',
  'price_includes_satellite_dish',
  'price_includes_lighting_fixtures',
  'price_includes_storms_and_screens',
  'price_includes_outdoor_play_set_swings',
  'price_includes_washer',
  'price_includes_attached_tv',
  'price_includes_electronic_garage_remote',
  'price_includes_outdoor_shed',
  'price_includes_dryer',
  'price_includes_tv_antenna',
  'price_includes_window_treatments',
  'price_includes_water_softener',
  'price_includes_multimedia_equipment',
  'price_includes_outdoor_tacked_down_carpeting',
  'smoke_detectors',
  'price_includes_home_warranty',
  'price_includes_stereo_speakers',
  'price_includes_other_equipment',
  'price_includes_refrigerator',
  'last_year_taxes',
  'pin',
  'electronic_account_number',
  'gas_account_number',
  'seller_transfers',
  'items_excluded',
  'sellers',
  'seller'
];
