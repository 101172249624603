/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import MaskedInput from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dropdown, Input, RadioButton } from '@xchange/uikit';
import { InputCurrency } from 'components';
import { StepPropertyInfoData } from './types';

const propertyTypeOptions = [
  'House',
  'Condo',
  'Townhouse',
  'Multi-family',
  'Land',
  'Other'
].map(item => ({ value: item, text: item }));

const parkingAndStorageOptions = ['Deeded', 'LCE', 'Assigned'].map(item => ({
  value: item,
  text: item
}));

interface StepPropertyInfoProps {
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: StepPropertyInfoData) => void;
}

const StepPropertyInfo: React.FC<StepPropertyInfoProps> = ({ onSubmit, defaultValues }) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    reset
  } = useForm({ defaultValues });
  const [condoPUD, listing_type_hr] = watch(['condoPUD', 'listing_type_hr']);
  const isCondo = listing_type_hr === 'Condo';

  const handlePropertyTypeChange = value => {
    if (value !== 'Condo') setValue('condoPUD', undefined);
    else setValue('lotSize', undefined);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return (
    <form className="process-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="process-form__title hide_temp">My Property is:</h3>
      <div className="process-form__radio-block hide_temp">
        <RadioButton
          {...register('saleOrRent', { required: true })}
          value="sale"
          label="For Sale"
          defaultChecked
          buttonLike
        />
        <RadioButton
          {...register('saleOrRent', { required: true })}
          value="rent"
          label="For Rent"
          buttonLike
        />
      </div>
      <div className="process-form__input-block">
        <Controller
          control={control}
          name="listing_type_hr"
          rules={{ required: 'Required' }}
          defaultValue={null}
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              options={propertyTypeOptions}
              error={errors.listing_type_hr?.message}
              className="process-form__input"
              label="Select Property Type*"
              placeholder="Select"
              onChange={value => {
                handlePropertyTypeChange(value);
                onChange(value);
              }}
              {...field}
            />
          )}
        />
{/* 
        <div className="process-form__input-block">
          <Input
            className="process-form__input"
            {...register('beds', { required: 'Required' })}
            error={errors.beds?.message}
            label="Enter Number of Bedrooms*"
            placeholder="Enter"
            type="number"
            min={0}
          />
          <Input
            className="process-form__input"
            {...register('baths', { required: 'Required' })}
            error={errors.baths?.message}
            label="Enter Number of Bathrooms*"
            placeholder="Enter"
            type="number"
            min={0}
          />
        </div> */}
      </div>
      {isCondo && (
        <div className="process-form__radio-block margin-top">
          <div className="label">Condo PUD*</div>
          <RadioButton
            {...register('condoPUD', { required: true })}
            value="yes"
            label="Yes"
            defaultChecked
          />
          <RadioButton {...register('condoPUD', { required: true })} value="no" label="No" />
        </div>
      )}

      <div className="process-form__input-block process-form__input-block_short">
        <Input
          className="process-form__input"
          {...register('sqft', {
            min: { value: 0, message: `Value can't be negative` }
          })}
          error={errors.sqft?.message}
          label="Living area (sq ft)"
          type="number"
          placeholder="Enter living area"
        />
        {!isCondo && (
          <Input
            className="process-form__input"
            {...register('lotSize')}
            error={errors.lotSize?.message}
            label="Lot Size (sq ft/acres)"
            placeholder="Enter lot size"
          />
        )}
      </div>
      <div className="process-form__input-block">
        {/* if Condo PUD yes */}
        {(!condoPUD || condoPUD === 'yes') && isCondo && (
          <>
            <Input
              className="process-form__input"
              {...register('condo_parking_number')}
              error={errors.condo_parking_number?.message}
              label="Parking Number"
              placeholder="Enter parking number"
            />
            <Controller
              control={control}
              name="condo_parking_info"
              defaultValue={null}
              render={({ field, formState: { errors } }) => (
                <Dropdown
                  options={parkingAndStorageOptions}
                  error={errors.condo_parking_info?.message}
                  className="process-form__input"
                  label="Parking Information"
                  placeholder="Deeded, LCE, or Assigned"
                  {...field}
                />
              )}
            />
            <Input
              className="process-form__input"
              {...register('condo_storage_number')}
              error={errors.condo_storage_number?.message}
              label="Storage Number"
              placeholder="Enter storage number"
            />
            <Controller
              control={control}
              name="condo_storage_info"
              defaultValue={null}
              render={({ field, formState: { errors } }) => (
                <Dropdown
                  options={parkingAndStorageOptions}
                  error={errors.condo_storage_info?.message}
                  className="process-form__input"
                  label="Storage Information"
                  placeholder="Deeded, LCE, or Assigned"
                  {...field}
                />
              )}
            />
          </>
        )}
      </div>

      {isCondo && (
        <>
          <h3 className="process-form__title">Management Company Contact:</h3>
          <div className="process-form__input-block">
            <Input
              className="process-form__input"
              {...register('managementCompanyContact.name')}
              error={errors.managementCompanyContact?.name?.message}
              label="Name"
              placeholder="Enter name"
            />
            <Input
              className="process-form__input"
              {...register('managementCompanyContact.email')}
              error={errors.managementCompanyContact?.email?.message}
              label="Email"
              placeholder="Enter email"
            />
            <Controller
              control={control}
              name="managementCompanyContact.phone"
              rules={{
                validate: value => !value?.includes('_') || 'Invalid format'
              }}
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Input
                  className="process-form__input"
                  as={MaskedInput}
                  mask="(999) 999-9999"
                  type="tel"
                  error={errors.managementCompanyContact?.phone?.message}
                  label="Phone Number"
                  placeholder="Enter phone number"
                  {...field}
                />
              )}
            />
            <br />
            <Controller
              control={control}
              name="managementCompanyContact.assessments"
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Input
                  label="Assessments ($)"
                  placeholder="Enter assessments"
                  error={errors.managementCompanyContact?.assessments?.message}
                  className="process-form__input"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="managementCompanyContact.assessmentsSpecial"
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Input
                  label="Special assessments ($)"
                  placeholder="Enter special assessments"
                  error={errors.managementCompanyContact?.assessmentsSpecial?.message}
                  className="process-form__input"
                  {...field}
                />
              )}
            />
          </div>
        </>
      )}
      <Button className="process-form__btn" type="submit">
        Continue
      </Button>
    </form>
  );
};

export default StepPropertyInfo;
