import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import MaskedInput from 'react-input-mask';

import Button from 'components/Button';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Input from 'components/Input';
import { InputDate } from '@xchange/uikit';
import CurrencyInput from 'components/InputCurrency';
import { REGEXP } from 'consts';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { addOrder, getVendors } from 'workspace/api';
import { CalendarItem } from 'views/CalendarPage/calendar.d';

interface AddOrderFormProps {
  task?: CalendarItem;
  onSuccess: () => void;
}

const AddOrderForm: React.FC<AddOrderFormProps> = ({ task, onSuccess }) => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loadingVendors, setLoadingVendors] = useState(true);
  const {
    register,
    control,
    formState: { errors },
    watch,
    formState,
    handleSubmit
  } = useForm<any>({
    defaultValues: {
      vendor: '',
      vendor_email: '',
      vendor_name: '',
      deadline: '',
      offered_price: '',
      note: ''
    }
  });
  const [vendorId] = watch(['vendor_id']);
  const vendorOptions: DropdownOption[] = [
    ...vendors.map(item => ({ value: item.id, text: item.name })),
    { value: 'Other', text: 'Other' }
  ];
  const vendorType =
    vendorId === 'Other' ? 'Other' : vendors.find(item => item.id === vendorId)?.type;

  const submit = async form => {
    try {
      await addOrder({
        ...form,
        type: vendorType === 'Other' ? task!.type : vendorType,
        task_id: task?.id
      });
      notify('Order sent');
      onSuccess();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    (async () => {
      setLoadingVendors(true);
      try {
        const vendors = await getVendors(task?.type);
        setVendors(vendors);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
      setLoadingVendors(false);
    })();
  }, [task]);

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <Controller
        control={control}
        name="vendor_id"
        defaultValue=""
        rules={{ required: 'Required' }}
        render={({ field, formState: { errors } }) => (
          <Dropdown
            options={vendorOptions}
            label="Select vendor"
            placeholder={loadingVendors ? 'Loading...' : 'Select'}
            error={errors.vendor_id?.message}
            {...field}
          />
        )}
      />
      {vendorId === 'Other' && (
        <>
          <Input
            {...register('vendor_name', { required: 'Required' })}
            error={errors.vendor_name?.message}
            label="Vendor name"
            placeholder="Enter"
          />
          <Input
            {...register('vendor_email', {
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email'
              }
            })}
            error={errors.vendor_email?.message}
            label="Email"
            placeholder="Enter"
          />
          <Controller
            control={control}
            name="vendor_phone"
            rules={{
              required: 'Required',
              validate: value => !String(value).includes('_') || 'Invalid phone number'
            }}
            defaultValue=""
            render={({ field, formState: { errors } }) => (
              <Input
                className="participant-form__control"
                as={MaskedInput}
                mask="(999) 999-9999"
                type="tel"
                error={errors.vendor_phone?.message}
                label="Phone Number"
                placeholder="Enter Phone Number"
                {...field}
              />
            )}
          />
        </>
      )}

      <Controller
        control={control}
        rules={{
          required: 'Required',
          pattern: {
            value: /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/,
            message: 'Invalid date'
          }
        }}
        name="deadline"
        defaultValue=""
        render={({ field, formState: { errors } }) => (
          <InputDate
            inputProps={{ label: 'Deadline', error: errors.deadline?.message }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="offered_price"
        defaultValue=""
        rules={{ required: 'Required' }}
        render={({ field, formState: { errors } }) => (
          <CurrencyInput
            label="Offered price"
            placeholder="Enter"
            error={errors.offered_price?.message}
            {...field}
          />
        )}
      />
      <Input
        {...register('note')}
        error={errors.note?.message}
        label="Note"
        placeholder="Enter note"
        as="textarea"
      />
      <Button type="submit" disabled={formState.isSubmitting}>
        Order
      </Button>
    </StyledForm>
  );
};

export default AddOrderForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }

  & > .input,
  & > .dropdown,
  & > .button {
    width: 100%;
  }

  & > .button {
    height: 40px;
  }

  textarea {
    resize: none;
  }
`;
