import { fetch,fetchWithToken } from 'utils/fetch';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export async function getListing(id: string): Promise<Listing> {
  const response = await fetch(`/listings/listing/${id}`);
  const data = await response.json();
  return mapListings([data])[0] as Listing;
}

export async function getListingTags(id: string) {
  const response = await fetchWithToken(`/tags/${id}`);
  const data = await response.json();
  //console.log(data);
  return data;
}

export async function removeListingTag(id: number) {
  await fetchWithToken(`/tags/${id}`, { method: 'DELETE' });
}

export async function addListingTag(payload: {
  listingId: string;
  tag: string;
}): Promise<boolean> {
  const response = await fetchWithToken(`/tags`, {
    method: 'POST',
    body: urlEncodeBody({ tag: payload.tag, listing_id: payload.listingId })
  });

  await response;

  return true;
}



export async function getListingsAddresses(params?: {
  search?: string;
}): Promise<{ id: string; propertyAddress: string }[]> {
  const response = await fetch(`/listings/addresses`, { query: urlEncodeBody(params).toString() });
  const data = await response.json();
  return data.map(item => ({ id: item.listing_id, propertyAddress: item.propertyAddress }));
}
