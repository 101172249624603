import React, { useState } from 'react';
import clsx from 'clsx';
import useAuthContext from 'hooks/useAuthContext';
import styled from '@emotion/styled';
import { camelToNormal,copyToClipboard } from 'utils/string';
import { Button, Icon } from 'components';

import iconFax from 'assets/icons/faxmachine.svg';

interface MembersTableItemProps {
  data: WorkspaceMember;
  message: (e: React.MouseEvent<HTMLButtonElement>) => void;
  edit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  remove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}



const MembersTableItem: React.FC<MembersTableItemProps> = ({ data, remove, edit , message }) => {
  const { businessPhone, secondaryEmail } = data;
  const [open, setOpen] = useState(false);
  const { authData } = useAuthContext();
  return (
    <StyledTableRow className={clsx('member', { open })}>
      <tr onClick={() => setOpen(v => !v)}>
        <td>
          <Icon name="plus-mini" size={10} />
        </td>
        <td onClick={(e) => {copyToClipboard(e)}}>{data.name}</td>
        <td>{camelToNormal(data.role==="SellerBrokerCoList"? "CoList" : data.role)}</td>
        <td>
          <span className="member__line">
            <Icon name="smartphone" />
            {<span onClick={(e) => {copyToClipboard(e)}}>{data.phone}</span> || <span className="no-info">No information</span>}
          </span>
        </td>
        <td onClick={(e) => {copyToClipboard(e)}}>{data.email}</td>
        <td>{data.companyName || data.contactPerson}</td>
      </tr>
      {open && (
        <>
          <tr>
            <td />
            <td colSpan={2}>
              <span className="member__line">
                <Icon name="home" />
                {data.address1 || <span className="no-info">No address</span>}
                {data.address1 ? <br></br> : ""}
                {data.address1 ? data.city+" "+data.state+" "+data.zip : ""}
              </span>
            </td>
            <td>
              <span className="member__line">
                <Icon name="phone" />
                {businessPhone || <span className="no-info">No information</span>}
              </span>
              {data.fax && (
                <br></br>
              )}
              {data.fax && (
                <span className="member__line">
                  <i className="faxmachine"/>
                  {data.fax}
                </span>
              )}
              
            </td>
            <td>{secondaryEmail || <span className="no-info">No information</span>}</td>
          </tr>
          {authData?.superAdmin && <tr>
            <td />
            <td colSpan={5}>
              <div className="members__btn-group user-buttons">
                <Button onClick={message} className="member__btn">Send message</Button>
                <Button onClick={edit} className="member__btn">
                  Edit user
                </Button>
                <Button secondary onClick={remove} className="member__btn">
                  ✗ Remove
                </Button>
              </div>
            </td>
          </tr>}
        </>
      )}
    </StyledTableRow>
  );
};

export default MembersTableItem;

const StyledTableRow = styled.tbody`
  height: 16px;

  .no-info {
    color: #545454;
  }

  .faxmachine {
    border: none;
    width: 16px;margin-right: 8px;
    height: 16px;
    background: url(${iconFax}) no-repeat;
    background-size: contain;
    cursor: pointer;
    filter: grayscale(100%);
  }

  &.open .icon-plus-mini {
    transform: rotate(45deg);
    fill: ${props => props.theme.colors.red};
  }

  &:not(:last-of-type) tr:last-of-type td {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  tr:first-of-type {
    cursor: pointer;
  }

  td {
    padding: 8px 0;
  }

  .member {
    &__photo {
      width: 24px;
      height: 24px;
      object-fit: contain;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__line {
      display: inline-flex;
      align-items: center;
      .icon {
        margin: 0 8px 0 0;
      }
    }
    &__btn {
      height: 24px;
    }
  }
`;
