import React, { useCallback, useEffect, useState } from 'react';
import { getListing , getListingTags, removeListingTag, addListingTag} from 'api/listings';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
  getMembers,
  deleteListing as deleteListingRequest,
  addListingMlsId as addListingMlsIdRequest,
  checkAsDualListing as checkAsDualListingRequest,
  setServicing as setServicingRequest,
  setAutoSyncXpCall as setAutoSyncXpCallRequest,
  setManualStatus
} from './api';

interface WorkspaceContextValues {
  workspaceOpen: boolean;
  openWorkspace: (listingId: string) => void;
  closeWorkspace: () => void;
  setWorkspaceListingId: (listingId: string) => void;
  listing: Listing | undefined;
  setListing: (listing: Listing) => void;
  listingId: string;
  workspaceTab: WorkspaceTabKey;
  setWorkspaceTab: (workspaceTab: WorkspaceTabKey) => void;
  fetchMembers: () => Promise<void>;
  members: WorkspaceMember[];
  setMembers: React.Dispatch<React.SetStateAction<WorkspaceMember[]>>;
  deleteListing: (value: string, callback?: () => void) => void;
  addListingMlsId: (value: string) => void;
  setServicing: (value: number) => void;
  checkAsDualListing: (value: number) => void;
  setAutoSyncXpCall: (value: number) => void;
  setManualStatusListing: (value: string) => void;
  fetchListing: () => void;
  fetchTags: () => void;
  deleteListingTag: (value: number) => void;
  tags: [];
  setTags: (value: any) => void;
  addListingTagToProp: (value: any) => void;
  emailDocumentsData: [];
  setEmailDocumentsData: React.Dispatch<React.SetStateAction<[]>>;
  emailDocumentsDataStep: number;
  setEmailDocumentsDataStep: React.Dispatch<React.SetStateAction<number>>;
  emailDocumentsDataMembersNoDocument: number;
  setEmailDocumentsDataMembersNoDocument: React.Dispatch<React.SetStateAction<number>>;
  isLinkedListing: boolean;
  setLinkedListing: React.Dispatch<React.SetStateAction<boolean>>;
  setServicedProperty: React.Dispatch<React.SetStateAction<boolean>>;
  isServicedProperty: boolean;
}

const WorkspaceContext = React.createContext({} as WorkspaceContextValues);

const defaultTab: WorkspaceTabKey = 'documents';

export const WorkspaceContextWrapper = ({ children }) => {
  const [query, setQuery] = useQueryParams({
    listingId: withDefault(StringParam, ''),
    workspaceTab: withDefault(StringParam, defaultTab) as QueryParamConfig<WorkspaceTabKey>
  });
  const [members, setMembers] = useState<WorkspaceMember[]>([]);
  const [emailDocumentsData, setEmailDocumentsData] = useState<[]>([]);
  const [emailDocumentsDataStep, setEmailDocumentsDataStep] = useState<number>(0);
  const [emailDocumentsDataMembersNoDocument, setEmailDocumentsDataMembersNoDocument] = useState<number>(-1);
  const [isLinkedListing, setLinkedListing] = useState<boolean>(false);
  const [isServicedProperty, setServicedProperty] = useState<boolean>(false);
  const { listingId, workspaceTab } = query;

  const [listing, setListing] = useState<Listing>();
  const [tags, setTags] = useState<any>();
  const workspaceOpen = Boolean(listingId);

  const setWorkspaceTab = (workspaceTab: WorkspaceTabKey) => {
    setQuery({ workspaceTab }, 'replaceIn');
  };

  const openWorkspace = (listingId: string) => {
    setQuery({ listingId, workspaceTab: defaultTab }, 'replaceIn');
  };

  const setWorkspaceListingId = (listingId: string) => {
    if (!listingId) return;
    setQuery({ listingId }, 'replaceIn');
  };

  const closeWorkspace = () => {
    setListing(undefined);
    setQuery({ listingId: undefined, workspaceTab: undefined });
    setMembers([]);
  };
  

  const fetchMembers = useCallback(async () => {
    if (!listingId) return;
    try {
      const members = await getMembers(listingId);
      setMembers(members);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  const fetchListing = useCallback(async () => {
    try {
      const listing = await getListing(listingId!);
      setListing(listing);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  }, [listingId]);

  const fetchTags = useCallback(async () => {
    try {
      var tags = await getListingTags(listingId!);
      setTags(tags);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  }, [listingId]);

  const deleteListing = async (listingId: string, callback?: () => void) => {
    try {
      await deleteListingRequest(listingId);
      closeWorkspace();
      notify('Listing deleted successfully');
      if (callback) callback();
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const setServicing = async (isServiced: number) => {
    try {
      const mlsListing = await setServicingRequest(listingId, isServiced);
      notify('Listing updated');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const deleteListingTag = async (tagId: number) => {
    try {
      await removeListingTag(tagId);
      notify('Tag deleted');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const addListingTagToProp = async (tag: string) => {
    try {
      await addListingTag({listingId, tag});
      notify('Tag added');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const checkAsDualListing = async (dualListing: number) => {
    try {
      const mlsListing = await checkAsDualListingRequest(listingId, dualListing);
      notify('Listing updated');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const setAutoSyncXpCall = async (xpSync: number) => {
    try {
      const mlsListing = await setAutoSyncXpCallRequest(listingId, xpSync);
      notify('Listing updated');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const setManualStatusListing = async (new_status: string) => {
    try {
      const mlsListing = await setManualStatus(listingId, new_status);
      window.location.reload();
      notify('Listing updated');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const addListingMlsId = async (listingMlsId: string) => {
    try {
      const mlsListing = await addListingMlsIdRequest(listingId, listingMlsId);
      setQuery({ listingId: mlsListing.listingId }, 'replaceIn');
      notify('MLS ID added successfully');
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  useEffect(() => {
    if (listingId) {
      fetchListing();
      fetchTags();
      fetchMembers();
    }
  }, [listingId, fetchListing, fetchMembers]);

  const values: WorkspaceContextValues = {
    openWorkspace,
    closeWorkspace,
    setWorkspaceListingId,
    workspaceOpen,
    workspaceTab,
    setWorkspaceTab,
    listing,
    setListing,
    listingId,
    fetchMembers,
    members,
    setMembers,
    deleteListing,
    addListingMlsId,
    setServicing,
    checkAsDualListing,
    setAutoSyncXpCall,
    setManualStatusListing,
    fetchListing,
    emailDocumentsData,
    setEmailDocumentsData,
    emailDocumentsDataStep,
    setEmailDocumentsDataStep,
    setEmailDocumentsDataMembersNoDocument,
    emailDocumentsDataMembersNoDocument,
    isLinkedListing, 
    setLinkedListing,
    setServicedProperty,
    isServicedProperty,
    fetchTags,
    tags,
    deleteListingTag,
    setTags,
    addListingTagToProp
  };
  return <WorkspaceContext.Provider value={values}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceContext;
