import React from 'react';
import styled from '@emotion/styled';
import notify from 'notify';
import useAuthContext from 'hooks/useAuthContext';
import { sendSignatureReminder } from 'api/documents';
import { info } from 'sitedata';

interface SignatureListProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: [];
  createdOn?: number,
  listingId?: string, 
  openSignatures?: boolean,
  documentId?: number,
  onEmpty?: () => void;
}


const SignatureList: React.FC<SignatureListProps> = ({
  items,
  createdOn,
  listingId,
  openSignatures,
  documentId,
  onEmpty
}) => {
    const { authData } = useAuthContext();

    const sendSignatureReminderFunction = (memberId,documentId) => {
        if(authData?.superAdmin ){
            sendSignatureReminder(memberId,documentId);
            onEmpty();
            notify('Signature reminder sent.');
        }else{
            notify('This function is not for view only accounts.');
        }
            
        
    }

    return (
        <StyledSignatureList>
            <table>
                
                {items?.map((item,key) => (  
                    <tr key={key}>
                        <td>{item.user_name}</td> <td className="signature-reminder-role"> {item.role.match(/[A-Z][a-z]+/g).join(" ")} </td> <td className='openedSignUSer'>{item.opened_on == null ? "Not Opened" : "Opened: " + new Date(item.opened_on * 1000).toLocaleString() }</td> <td>{item.signed ? <span className="ttSigned">Signed!</span> : <span onClick={e => sendSignatureReminderFunction(item.id,documentId)} className="ttNotSigned">Send signature reminder</span>}</td>
                    </tr>
                ))}
                {openSignatures==false &&
                    <tr>
                        <td><a target="_blank" href={info.apiUrl+(info.apiUrl.slice(-1) !== '/' ? "/":"")+"documents/certificates/"+documentId+"/"+listingId+"/"+(createdOn/1000)}>View Certificate</a></td>
                    </tr>
                }
            </table>
            
        </StyledSignatureList>
    );
};

export default SignatureList;

const StyledSignatureList = styled.div`
    .signature-reminder-role{font-weight: bold;}
    .ttNotSigned{color: #df1721;cursor:pointer;}
    .ttSigned{color: #62B120;}
    .openedSignUSer{font-style: italic;}
`

