import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Checkbox, Button, Input } from '@xchange/uikit';
import { BuyProcessPriceIncludesData } from './types';
import priceIncludesCheckboxes from './priceIncludesCheckboxes.json';

const STEP = 4;

interface BuyProcessFormPriceIncludesProps {
  isEditing?: boolean;
  defaultValues?: Partial<BuyProcessPriceIncludesData>;
  onStartEdit: (step: number) => void;
  onSubmit: (values: BuyProcessPriceIncludesData) => void;
}

const BuyProcessFormPriceIncludes: React.FC<BuyProcessFormPriceIncludesProps> = ({
  isEditing,
  onStartEdit,
  onSubmit,
  defaultValues
}) => {
  const { register, handleSubmit } = useForm({ defaultValues });
  const [viewMore, setViewMore] = useState(false);

  if (!isEditing && defaultValues) {
    let overflow = false;
    const priceIncludesItems = Object.entries(defaultValues.price_includes || {}).filter(
      ([, value]) => value
    );
    if (priceIncludesItems.length > 10 && !viewMore) {
      priceIncludesItems.length = 10;
      overflow = true;
    }
    return (
      <StyledBuyProcessPriceIncludesData className="step-info">
        <div className="step-info__content">
          {priceIncludesItems.length === 0 && <span>None</span>}
          {priceIncludesItems.map(([key]) => (
            <span key={key}>{priceIncludesCheckboxes[key]}</span>
          ))}
          {(overflow || viewMore) && (
            <Button className="view-more-btn" simple onClick={() => setViewMore(v => !v)}>
              {viewMore ? 'Collapse' : 'View More'}
            </Button>
          )}
        </div>
        <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
          Edit
        </Button>
      </StyledBuyProcessPriceIncludesData>
    );
  }

  return (
    <StyledBuyProcessFormPriceIncludes className="finance-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="checkbox-group">
        {Object.entries(priceIncludesCheckboxes).map(([key, label]) => (
          <Checkbox {...register(`price_includes.${key}` as any)} label={label} key={key} />
        ))}
      </div>
      <Input
        {...register('items_at_zero_cost')}
        label="Items included at zero cost"
        placeholder="Enter items included at zero cost"
      />
      <Input
        {...register('items_not_included')}
        label="Items not included"
        placeholder="Enter items not included"
      />
      <p>Condo Information (optional)</p>
      <div className="finance-form__input-container">
        <Input
          {...register('parking_spots')}
          label="Number of parking spots"
          placeholder="Enter number of parking spots"
        />
        <Input
          {...register('parking_spots_numbers')}
          label="Parking spot numbers"
          placeholder="Enter parking spots numbers"
        />
        <Input
          {...register('parking_spots_location')}
          label="Parking spot location"
          placeholder="Enter parking spots location"
        />
      </div>
      <Input
          {...register('parking_pin')}
          label="Parking spot PIN (if deeded)"
          placeholder="Enter parking spots PINs"
        />
        <div className="finance-form__input-container">
        <Input
          {...register('storage_spots')}
          label="Number of Storage spots"
          placeholder="Enter number of Storage spots"
        />
        <Input
          {...register('storage_spots_numbers')}
          label="Storage spot numbers"
          placeholder="Enter Storage spots numbers"
        />
        <Input
          {...register('storage_spots_location')}
          label="Storage spot location"
          placeholder="Enter Storage spots location"
        />
      </div>
      <Button className="finance-form__submit-btn">Next</Button>
    </StyledBuyProcessFormPriceIncludes>
  );
  
};

export default BuyProcessFormPriceIncludes;

const StyledBuyProcessFormPriceIncludes = styled.form`
  display: flex;
  flex-direction: column;

  .checkbox-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 8px 0px;
    margin-bottom: 20px;
  }

  .finance-form__input-group {
    display: grid;
    grid-template-columns: repeat(3, 183px);
    grid-gap: 80px;
  }

  .finance-form__input-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 20px;
    .finance-form__input {
      width: 100%;
    }
    .dropdown__button {
      height: 32px;
    }
  }
  
  .input {
    margin-bottom: 20px;
  }

  .finance-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
  }
`;

const StyledBuyProcessPriceIncludesData = styled.div`
  .view-more-btn {
    font-size: 12px;
    line-height: 18px;
    text-decoration: underline;
    color: ${props => props.theme.colors.red};
  }
`;
