import React from 'react';
import styled from '@emotion/styled';
import { Button, Icon } from '@xchange/uikit';

interface EditMembersMenuProps {
  title: React.ReactNode;
  onClose: () => void;
}

const EditMembersMenu: React.FC<EditMembersMenuProps> = ({ title, children, onClose }) => {
  return (
    <StyledEditMembersMenu>
      <h2 className="title">
        <Button secondary onClick={onClose} className="go-back-button">
          <Icon name="arrow-simple" size={16} />
        </Button>
        {title}
      </h2>
      <div className="add-members-columns">
        <div className="content">{children}</div>
      </div>
    </StyledEditMembersMenu>
  );
};

export default EditMembersMenu;

const StyledEditMembersMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin: 32px 0 20px;
    display: flex;
    align-items: center;
  }

  .add-members-columns {
    display: grid;
    height: 100%;
    overflow: auto;
    margin-bottom: 32px;
    margin-right: -16px;
    grid-gap: 32px;

    & > * {
      width: 100%;
    }
  }

  .template-button {
    font-size: 14px;
    line-height: 20px;
    height: 80px;
    border: 1px solid #8b8b8b;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: none;
    cursor: pointer;
    outline: none;

    &.active {
      background: ${props => props.theme.colors.lightGray};
    }

    &:hover {
      border: 1px solid ${props => props.theme.colors.red};
    }
  }

  .extra-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 32px;
  }

  .go-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0 auto;
    padding-right: 16px;
    height: 100vh;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .add-members-columns {
      display: block;
      margin-bottom: 40px;
      margin-right: -10px;
      .content {
        padding-right: 10px;
      }
    }
  }
`;
